import React from 'react';
import NikahFinder from './pages/HomePage'

import './App.css';
import Login  from './componenet/Login/Login';



function App() {
    return (
        <div className="app">
            <NikahFinder />
            {/* <Login /> */}
            
        </div>
    );
}

export default App;
