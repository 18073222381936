// import React from 'react'
// import "../profie/Profile.css"


// const Property = () => {
//     const properties = [
//         {
//             id: 1,
//             image: "https://mighty.tools/mockmind-api/content/human/41.jpg",
//             title: "Sapphire",
//             address: "2699 Green Velley Highland Lake, FL",
//         },
//         {
//             id: 2,
//             image: "https://mighty.tools/mockmind-api/content/human/8.jpg",
//             title: "Aqua",
//             address: "2699 Green Velley Highland Lake, FL",
//         },
//         {
//             id: 3,
//             image: "https://mighty.tools/mockmind-api/content/human/41.jpg",
//             title: "Olive",
//             address: "2699 Green Velley Highland Lake, FL",
//         },
//         {
//             id: 4,
//             image: "https://mighty.tools/mockmind-api/content/human/8.jpg",
//             title: "Skyz",
//             address: "2699 Green Velley Highland Lake, FL",
//         },
//         {
//             id: 3,
//             image: "https://mighty.tools/mockmind-api/content/human/41.jpg",
//             title: "Olive",
//             address: "2699 Green Velley Highland Lake, FL",
//         },
//         {


//             id: 3,
//             image: "https://mighty.tools/mockmind-api/content/human/41.jpg",
//             title: "Olive",
//             address: "2699 Green Velley Highland Lake, FL",
//         },
//         {
//             id: 3,
//             image: "https://mighty.tools/mockmind-api/content/human/41.jpg",
//             title: "Olive",
//             address: "2699 Green Velley Highland Lake, FL",
//         },
//         // {
//         //     id: 1,
//         //     image: "https://mighty.tools/mockmind-api/content/human/41.jpg",
//         //     title: "Sapphire",
//         //     address: "2699 Green Velley Highland Lake, FL",
//         // },
//     ];

//     return (
//         <div className="property">
//             <div className="title-container">
//                 <h6 className="photos-title">Property</h6>
//             </div>
//             <div className="property-cards">
//                 {properties.map((property) => (
//                     <div className="property-card" key={property.id}>
//                         <img
//                             alt="property"
//                             className="pro-img"
//                             src={property.image}
//                         />
//                         <h3>{property.title}</h3>
//                         <p>{property.address}</p>
//                     </div>
//                 ))}
//             </div>
//             <div className="button_container">
//                 <a href="#" className="PropertButton">
//                     Load More
//                 </a>
//             </div>
//         </div>
//     );
// };

// export default Property;




import React, { useState, useEffect } from "react";
import "../profie/Profile.css"
import axios from 'axios'

const Property = () => {
    const [properties, setProperties] = useState([]);
    const [visibleProperties, setVisibleProperties] = useState(6);

    // Function to fetch property data from the API
    const fetchProperties = async () => {
        try {
            const config = {
                method: "get",
                url: "https://api.clickmee.site/api/profile/all",
                headers: {
                    "Content-Type": "application/json",
                },
              };
              const data = await axios.request(config);
              console.log("API data:", data);

              console.log("API Response:", data.data.data);
            setProperties(data.data.data);
        } catch (error) {
            console.error("Error fetching properties:", error);
        }
    };

    useEffect(() => {
        fetchProperties();
    }, []);

    // Function to handle the "Load More" button click
    const loadMoreProperties = () => {
        setVisibleProperties((prevCount) => prevCount + 6);
    };

    return (
        <div className="property">
            <div className="title-container">
                <h6 className="photos-title">Profile</h6>
            </div>
            <div className="property-cards">
                {properties.slice(0, visibleProperties).map((property) => (
                    <div className="property-card" key={property.id}>
                        <img
                            alt="profile"
                            className="pro-img"
                            // src={property.profile_picture}
                            src={`https://api.clickmee.site/storage/photos/${property.profile_picture}`}
                        />
                        <h3>{property.name} <span>(Age: {property.age})</span></h3>
                        <p>{property.state}</p>
                    </div>
                ))}
            </div>
            {visibleProperties < properties.length && (
                <div className="button_container">
                    <button onClick={loadMoreProperties} className="PropertButton">
                        Load More
                    </button>
                </div>
            )}
        </div>
    );
};

export default Property;
