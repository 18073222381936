import React, { useState } from 'react';
import './StepperForm.css'; // Import your CSS for styling
import axios from 'axios'
// // Define the steps
// const steps = [
//   'Personal Details',
//   'Education Details',
//   'Contact Details',
//   'Past Agency Details',
//   'Work Experience',
//   'Bank Details',
//   'Nominee Details'
// ];

// // Step components for each form section
// const PersonalDetailsForm = () => (
//   <div>
//     <h2>Personal Details</h2>
//     <div className="form-row">
//       <input type="text" placeholder="First Name" />
//       <input type="text" placeholder="Last Name" />
//     </div>
//     <div className="form-row">
//       <input type="email" placeholder="Email" />
//       <input type="email" placeholder="Alternate Email" />
//     </div>
//   </div>
// );

// const EducationDetailsForm = () => (
//   <div>
//     <h2>Education Details</h2>
//     <div className="form-row">
//       <select>
//         <option value="Bachelors">Bachelors</option>
//         <option value="Masters">Masters</option>
//       </select>
//       <input type="text" placeholder="Roll Number" />
//     </div>
//     <div className="form-row">
//       <input type="text" placeholder="Board Name" />
//       <input type="date" placeholder="Year Of Passing" />
//     </div>
//   </div>
// );

// const WorkExperienceForm = () => (
//   <div>
//     <h2>Work Experience</h2>
//     <div className="form-row">
//       <input type="text" placeholder="Company Name" />
//       <input type="text" placeholder="Position" />
//     </div>
//     <div className="form-row">
//       <input type="number" placeholder="Years of Experience" />
//     </div>
//   </div>
// );

// const BankDetailsForm = () => (
//   <div>
//     <h2>Bank Details</h2>
//     <div className="form-row">
//       <input type="text" placeholder="Bank Name" />
//       <input type="text" placeholder="Account Number" />
//     </div>
//     <div className="form-row">
//     <input type="text" placeholder="IFSC Code" className="half-width" />
//     </div>
//   </div>
// );

// const NomineeDetailsForm = () => (
//   <div>
//     <h2>Nominee Details</h2>
//     <div className="form-row">
//       <input type="text" placeholder="Nominee Name" />
//       <input type="text" placeholder="Relation" />
//     </div>
//   </div>
// );

// const StepperForm = ({ isFormVisible }) => {
//   const [activeStep, setActiveStep] = useState(0);

//   // Move to the next step
//   const handleNext = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep + 1);
//   };

//   // Go to the previous step
//   const handleBack = () => {
//     setActiveStep((prevActiveStep) => prevActiveStep - 1);
//   };

//   // Reset to the first step
//   const handleReset = () => {
//     setActiveStep(0);
//   };

//   // Set the active step when clicking on a step
//   const handleStepClick = (step) => {
//     setActiveStep(step);
//   };

//   // Get the content of the active step
//   const getStepContent = (step) => {
//     switch (step) {
//       case 0:
//         return <PersonalDetailsForm />;
//       case 1:
//         return <EducationDetailsForm />;
//       case 2:
//         return <div> {/* ContactDetailsForm component here */}</div>;
//       case 3:
//         return <div> {/* PastAgencyDetailsForm component here */}</div>;
//       case 4:
//         return <WorkExperienceForm />;
//       case 5:
//         return <BankDetailsForm />;
//       case 6:
//         return <NomineeDetailsForm />;
//       default:
//         return 'Unknown step';
//     }
//   };

//   return (
//     <div className="stepper-form">
//       {isFormVisible && (
//         <div>
//           <div>
//             <ul className="stepper">
//               {steps.map((label, index) => (
//                 <li
//                   key={index}
//                   className={activeStep === index ? 'active' : ''}
//                   onClick={() => handleStepClick(index)}
//                 >
//                   {label}
//                 </li>
//               ))}
//             </ul>
//           </div>
//           <div>
//             {activeStep === steps.length ? (
//               <div>
//                 <h2>All steps completed</h2>
//                 <button onClick={handleReset}>Reset</button>
//               </div>
//             ) : (
//               <div>
//                 <div className="step-content">{getStepContent(activeStep)}</div>
//                 <div className="step-buttons">
//                   <button disabled={activeStep === 0} onClick={handleBack}>
//                     Back
//                   </button>
//                   <button onClick={handleNext}>
//                     {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
//                   </button>
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default StepperForm;




// Section components for each form section
// const PersonalDetailsForm = () => (
//   <div>
//     <h2>Personal Details</h2>
//     <div className="form-row">
//       <label htmlFor=""></label>
//       <input type="text" placeholder="First Name" />
//       <input type="text" placeholder="Last Name" />
//     </div>
//     <div className="form-row">
//       <input type="email" placeholder="Email" />
//       <input type="email" placeholder="Alternate Email" />
//     </div>
//   </div>
// );


// const PersonalDetailsForm = () => (
//   <div>
//     <h2>Personal Details</h2>
//     <div className="form-row">
//       <label htmlFor="first_name">First Name</label>
//       <input type="text" id="first_name" name="first_name" placeholder="First Name" />
//       <label htmlFor="last_name">Last Name</label>
//       <input type="text" id="last_name" name="last_name" placeholder="Last Name" />
//     </div>
//     <div className="form-row">
//       <label htmlFor="age">Age</label>
//       <input type="text" id="age" name="age" placeholder="Age" />
//       <label htmlFor="email">Email</label>
//       <input type="email" id="email" name="email" placeholder="Email" />
//     </div>
//     <div className="form-row">
//       <label htmlFor="gender">Gender</label>
//       <input type="text" id="gender" name="gender" placeholder="Gender" />
//       <label htmlFor="religion">Religion</label>
//       <input type="text" id="religion" name="religion" placeholder="Religion" />
//     </div>
//     <div className="form-row">
//       <label htmlFor="caste">Caste</label>
//       <input type="text" id="caste" name="caste" placeholder="Caste" />
//       <label htmlFor="location">Location</label>
//       <input type="text" id="location" name="location" placeholder="Location" />
//     </div>
//     <div className="form-row">
//       <label htmlFor="occupation">Occupation</label>
//       <input type="text" id="occupation" name="occupation" placeholder="Occupation" />
//       <label htmlFor="annualIncome">Annual Income</label>
//       <input type="text" id="annualIncome" name="annualIncome" placeholder="Annual Income" />
//     </div>
//     <div className="form-row">
//       <label htmlFor="drinkingStatus">Drinking Status</label>
//       <select id="drinkingStatus" name="drinkingStatus">
//         <option value="">Select</option>
//         <option value="yes">Yes</option>
//         <option value="no">No</option>
//       </select>
//       <label htmlFor="interests">Interests</label>
//       <input type="text" id="interests" name="interests" placeholder="Interests" />
//     </div>
//     <div className="form-row">
//       <label htmlFor="bio">Bio</label>
//       <input type="text" id="bio" name="bio" placeholder="Bio" />
//       <label htmlFor="family_details">Family Details</label>
//       <input type="text" id="family_details" name="family_details" placeholder="Family Details" />
//     </div>
//     <div className="form-row">
//       <label htmlFor="preferred_languages">Preferred Languages</label>
//       <select id="preferred_languages" name="preferred_languages">
//         <option value="">Select</option>
//         <option value="english">English</option>
//         <option value="hindi">Hindi</option>
//         <option value="urdu">Urdu</option>
//         <option value="bengali">Bengali</option>
//         <option value="marathi">Marathi</option>
//       </select>
//       <label htmlFor="hobbies">Hobbies</label>
//       <input type="text" id="hobbies" name="hobbies" placeholder="Hobbies" />
//     </div>
//     <div className="form-row">
//       <label htmlFor="profilePicture">Upload Profile Picture</label>
//       <input
//         type="file"
//         id="profilePicture"
//         name="profilePicture"
//         accept="image/*"
//       />
//     </div>
//   </div>
// );
const PersonalDetailsForm = ({ handleChange, user, handleFileChange, error }) => {
  // { console.log(error, "rrrrrrrrrrrrrr") }
  return (
    <div>
      <h2>Personal Details</h2>
      <div className="form-row">
        <div>
          <label htmlFor="name">Full Name<span style={{ color: 'red' }}>*</span></label>
          <input type="text" id="name" name="name" value={user.name} placeholder="First Name" onChange={handleChange} />
          {error?.name && <p
            className="error-message"
          >
            {error?.name}</p>}
        </div>
        <div>
          {/* <label htmlFor="last_name">Last Name</label>
        <input type="text" id="last_name" name="last_name" placeholder="Last Name" /> */}
          <label htmlFor="age">Age<span style={{ color: 'red' }}>*</span></label>
          <input type="number" id="age" name="age" placeholder="Age" value={user.age} onChange={handleChange} required />
          {error?.age && <p
            className="error-message"
          >
            {error?.age}</p>}
        </div>
      </div>
      <div className="form-row">
        <div>
          <label htmlFor="profile_for">Profile For<span style={{ color: 'red' }}>*</span></label>
          <select id="profile_for" name="profile_for" value={user.profile_for} onChange={handleChange}>
            <option value="">Select</option>
            <option value="Self">Self</option>
            <option value="Son">Son</option>
            <option value="Daughter">Daughter</option>
            <option value="Brother">Brother</option>
            <option value="Sister">Sister</option>
            <option value="Nephew">Nephew</option>
            <option value="Niece">Niece</option>
            <option value="Other">Other</option>
          </select>
          {error?.profile_for && <p
            className="error-message"
          >
            {error?.profile_for}</p>}
        </div>
        <div>
          <label htmlFor="dob">DOB<span style={{ color: 'red' }}>*</span></label>
          <input type="Date" id="dob" name="dob" value={user.dob} placeholder="Dob" onChange={handleChange} />
          {error?.dob && <p
            className="error-message"
          >
            {error?.dob}</p>}
        </div>
      </div>
      <div className="form-row">
        <div>
          {/* <label htmlFor="age">Age</label>
        <input type="text" id="age" name="age" placeholder="Age" /> */}
          <label htmlFor="hobbies">Hobbies</label>
          <input type="text" id="hobbies" name="hobbies" placeholder="Hobbies" value={user.hobbies} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" placeholder="Email" value={user.email} onChange={handleChange} />
        </div>
      </div>
      <div className="form-row">
        <div>
          {/* <label htmlFor="gender">Gender</label>
        <input type="text" id="gender" name="gender" placeholder="Gender"  value={user.gender} onChange={handleChange} /> */}
          <label htmlFor="gender">Gender<span style={{ color: 'red' }}>*</span></label>
          <select id="gender" name="gender" value={user.gender} onChange={handleChange} required>
            <option value="">Select</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
          {/* <div> */}
          {error?.gender && <p
            className="error-message"
          >
            {error?.gender}</p>}
          {/* </div> */}
        </div>
        <div>
          <label htmlFor="religion">Religion</label>
          <input type="text" id="religion" name="religion" placeholder="Religion" value={user.religion} onChange={handleChange} />
        </div>
      </div>
      <div className="form-row">
        <div>
          <label htmlFor="caste">Caste</label>
          <input type="text" id="caste" name="caste" placeholder="Caste" value={user.caste} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="education">Education</label>
          <input type="text" id="education" name="education" placeholder="Education" value={user.education} onChange={handleChange} />
        </div>
      </div>
      <div className="form-row">
        <div>
          <label htmlFor="address_line_1">Address Line 1</label>
          <input type="text" id="address_line_1" name="address_line_1" placeholder="Address Line 1" value={user.address_line_1} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="address_line_2">Address line 2</label>
          <input type="text" id="address_line_2" name="address_line_2" placeholder="Address line 2" value={user.address_line_2} onChange={handleChange} />
        </div>
      </div>
      <div className="form-row">
        <div>
          <label htmlFor="pin_code">Pincode</label>
          <input type="number" id="pin_code" name="pin_code" placeholder="Pincode" value={user.pin_code} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="city">City</label>
          <input type="text" id="city" name="city" placeholder="City" value={user.city} onChange={handleChange} />
        </div>
      </div>
      <div className="form-row">
        <div>
          <label htmlFor="state">State</label>
          <input type="text" id="state" name="state" placeholder="State" value={user.state} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="country">Country</label>
          <input type="text" id="country" name="country" placeholder="Country" value={user.country} onChange={handleChange} />
        </div>
      </div>
      <div className="form-row">
        <div>
          <label htmlFor="marital_status">Marital Status</label>
          <select id="marital_status" name="marital_status" value={user.marital_status} onChange={handleChange}>
            <option value="">Select</option>
            <option value="Single">Single</option>
            <option value="Separated">Separated</option>
            <option value="Divorced">Divorced</option>
            <option value="Widowed">Widowed</option>
          </select>
        </div>
        <div>
          <label htmlFor="mobile_no">Mobile No<span style={{ color: 'red' }}>*</span></label>
          <input type="number" id="mobile_no" name="mobile_no" value={user.mobile_no} placeholder="Mobile No" onChange={handleChange} />
          {error?.mobile_no && <p
            className="error-message"
          >
            {error?.mobile_no}</p>}
        </div>
      </div>
      <div className="form-row">
        <div>
          <label htmlFor="occupation">Occupation</label>
          <input type="text" id="occupation" name="occupation" placeholder="Occupation" value={user.occupation} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="annual_income">Annual Income</label>
          <input type="text" id="annual_income" name="annual_income" placeholder="Annual Income" value={user.annual_income} onChange={handleChange} />
        </div>
      </div>
      <div className="form-row">
        <div>
          <label htmlFor="drinking_status">Drinking Status</label>
          <select id="drinking_status" name="drinking_status" value={user.drinking_status} onChange={handleChange}>
            <option value="">Select</option>
            <option value="Occasional">Occasional</option>
            <option value="No">No</option>
            <option value="Yes">Yes</option>
          </select>
        </div>
        <div>
          <label htmlFor="interests">Interests</label>
          <input type="text" id="interests" name="interests" value={user.interests} placeholder="Interests" onChange={handleChange} />
        </div>
      </div>
      <div className="form-row">
        <div>
          <label htmlFor="smoking_status">Smoking Status</label>
          <select id="smoking_status" name="smoking_status" value={user.smoking_status} onChange={handleChange}>
            <option value="">Select</option>
            <option value="Occasional">Occasional</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
        <div>
          <label htmlFor="lifestyle">Life Style</label>
          <select id="lifestyle" name="lifestyle" value={user.lifestyle} onChange={handleChange}>
            <option value="">Select</option>
            <option value="Traditional">Traditional</option>
            <option value="Liberal">Liberal</option>
            <option value="Moderate">Moderate</option>
          </select>
        </div>
      </div>
      <div className="form-row">
        <div>
          <label htmlFor="bio">Bio</label>
          <input type="text" id="bio" name="bio" placeholder="Bio" value={user.bio} onChange={handleChange} />
        </div>
        <div>
          <label htmlFor="family_details">Family Details</label>
          <input type="text" id="family_details" name="family_details" value={user.family_details} placeholder="Family Details" onChange={handleChange} />
        </div>
      </div>
      <div className="form-row">
        <div>
          <label htmlFor="preferred_languages">Preferred Languages</label>
          <select id="preferred_languages" name="preferred_languages" value={user.preferred_languages} onChange={handleChange}>
            <option value="">Select</option>
            <option value="english">English</option>
            <option value="hindi">Hindi</option>
            <option value="urdu">Urdu</option>
            <option value="bengali">Bengali</option>
            <option value="marathi">Marathi</option>
          </select>
        </div>
        <div>
          <label htmlFor="profilePicture">Upload Profile Picture</label>
          <input
            type="file"
            id="profilePicture"
            name="profilePicture"
            accept="image/*"
            onChange={handleFileChange}
            multiple
          />
          {error?.profilePicture && <p
            className="error-message"
          >
            {error?.profilePicture}</p>}
        </div>
      </div>

    </div>
  )
}

const PartnerPreference = ({ handleChange, user, error }) => (
  <div>
    <h2>Partner Preference</h2>
    <div className="form-row">
      <div>
        <label htmlFor="age_min">Min Age<span style={{ color: 'red' }}>*</span></label>
        <input type="number" id="age_min" name="age_min" placeholder="Min Age" value={user.age_min} onChange={handleChange} />
        {error?.age_min && <p
          className="error-message"
        >
          {error?.age_min}</p>}
      </div>
      <div>
        <label htmlFor="age_max">Max Age<span style={{ color: 'red' }}>*</span></label>
        <input type="number" id="age_max" name="age_max" placeholder="Max Age" value={user.age_max} onChange={handleChange} />
        {error?.age_max && <p
          className="error-message"
        >
          {error?.age_max}</p>}
      </div>
    </div>
    <div className="form-row">
      <div>
        <label htmlFor="religion">Religion</label>
        <input type="text" id="religion" name="religion" placeholder="Religion" value={user.religion} onChange={handleChange} />
      </div>
      <div>
        <label htmlFor="preferred_caste">Caste</label>
        <input type="text" id="preferred_caste" name="preferred_caste" placeholder="Cast" value={user.preferred_caste} onChange={handleChange} />
      </div>
    </div>
    <div className="form-row">
      <div>
        <label htmlFor="preferred_locations">Location</label>
        <input type="text" id="preferred_locations" name="preferred_locations" placeholder="Location" value={user.preferred_locations} onChange={handleChange} />
      </div>
      <div>
        <label htmlFor="preferred_occupations">Occupation</label>
        <input type="text" id="preferred_occupations" name="preferred_occupations" placeholder="Occupation" value={user.preferred_occupations} onChange={handleChange} />
      </div>
    </div>
    <div className="form-row">
      <div>
        <label htmlFor="education_level">Education</label>
        <input type="text" id="education_level" name="education_level" value={user.education_level} placeholder="Education" onChange={handleChange} />
      </div>
      <div>
        <label htmlFor="income_range">Income Range</label>
        <input type="text" id="income_range" name="income_range" value={user.income_range} placeholder="Income Range" onChange={handleChange} />
      </div>
    </div>
    <div className="form-row">
      <div>
        <label htmlFor="smoking_preference">Smoking Preference</label>
        <select id="smoking_preference" name="smoking_preference" value={user.smoking_preference} onChange={handleChange}>
          <option value="">Select</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
          <option value="Doesn’t Matter">Doesn’t Matter</option>
        </select>
      </div>
      <div>
        <label htmlFor="drinking_preference">Drinking Preference</label>
        <select id="drinking_preference" name="drinking_preference" value={user.drinking_preference} onChange={handleChange}>
        <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
            <option value="Doesn’t Matter">Doesn’t Matter</option>
        </select>
      </div>
    </div>
    <div className="form-row">
      <div>
        <label htmlFor="lifestyle_preference">Lifestyle Preference</label>
        {/* <input type="text" id="lifestyle_preference" name="lifestyle_preference" value={user.lifestyle_preference} placeholder="Lifestyle Preference" onChange={handleChange} /> */}
        <select id="lifestyle_preference" name="lifestyle_preference" value={user.lifestyle_preference} onChange={handleChange}>
            <option value="">Select</option>
            <option value="Traditional">Traditional</option>
            <option value="Liberal">Liberal</option>
            <option value="Moderate">Moderate</option>
          </select>
      </div>
      <div>
        <label htmlFor="house_status">House Status<span style={{ color: 'red' }}>*</span></label>
        {/* <input type="text" id="house_status" name="house_status" value={user.house_status} placeholder="house_status" onChange={handleChange} /> */}
        <select id="house_status" name="house_status" value={user.house_status} onChange={handleChange}>
            <option value="">Select</option>
            <option value="Own">Own</option>
            <option value="Rented">Rented</option>
            <option value="Any">Any</option>
          </select>
          {error?.house_status && <p
            className="error-message"
          >
            {error?.house_status}</p>}
      </div>
    </div>
  </div>
);




// Main form with scrollable container
// const ScrollableForm = ({ isFormVisible, }) => {
//   return (
//     <div className="scrollable-form">
//       {isFormVisible && (
//         <div>
//           <div className="popupheader">
//           <h1>Registration Form</h1>
//           <div className="popupclose">
//           &times;
//           </div>
//           </div>

//           <div className="scroll-container">
//             <PersonalDetailsForm />
//             <EducationDetailsForm />
//             <WorkExperienceForm />
//             <BankDetailsForm />
//             <NomineeDetailsForm />
//           </div>
//           <div className="form-buttons">
//             <button type="submit" className="submit-button">
//               Submit
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };



// const StepperForm = ({ isFormVisible, closeModal }) => {
//   const [user, setUser] = useState({})
//   function handleChange(e) {
//     console.log("test user",e.target.name, e.target.value, e.target.type,e.target.files)
//     const { name, value, type, files } = e.target;
//     if (type === "file") {
//       // For file inputs, store the first file object in the state
//       setUser((prevUser) => ({
//         ...prevUser,
//         [name]: files[0], // Store the file object
//       }));
//     } else {
//       // For text and other inputs
//       setUser((prevUser) => ({
//         ...prevUser,
//         [name]: value,
//       }));
//     }
//     // setUser({
//     //     ...user,
//     //     [name] : value
//     // })
// }
// const handleSubmit = async () => {
//   console.log(user,"99999999999999999999999999999999")

//   const formData = new FormData();
//   Object.keys(user).forEach((key) => {
//     console.log("key",key)
//     formData.append(key, user[key]);
//   });
// console.log("formData",formData)
// let configData = {
//   method: "post",
//   url: 'https://api.clickmee.site/api/profile/store',
//   headers: {
//       'Content-Type': "application/json",
//   },
//   data:formData
// };
// console.log("configData",configData)
// let storeResult = await axios.request(configData)
// console.log(storeResult,"storeResult")
// };


//   return (
//     <div className="scrollable-form">
//       {isFormVisible && (
//         <div>
//           <div className="popupheader">
//             <h1>Registration Form</h1>
//             <button className="popupclose" onClick={closeModal}>
//               &times; {/* Close button */}
//             </button>
//           </div>
//           <div className="scroll-container">
//             <PersonalDetailsForm handleChange={handleChange} />
//             <PartnerPreference handleChange={handleChange} />
//           </div>
//           <div className="form-buttons">
//             <button type="submit" className="submit-btn" onClick={handleSubmit}>
//               Submit
//             </button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

const StepperForm = ({ isFormVisible, closeModal }) => {
  const defaultUserState = {
    name: "",
    email: "",
    age: "",
    profile_for: "",
    gender: "",
    religion: "",
    dob:"",
    caste: "",
    marital_status: "",
    mobile_no: "",
    education: "",
    occupation: "",
    annual_income: "",
    drinking_status: "",
    interests: "",
    smoking_status: "",
    lifestyle: "",
    bio: "",
    family_details: "",
    preferred_languages: "",
    profilePicture: null,
    age_min: null,
    age_max: null,
    preferred_caste: "",
    education_level: "",
    preferred_occupations: "",
    preferred_locations: "",
    income_range: "",
    smoking_preference: "",
    drinking_preference: "",
    lifestyle_preference: "",
    house_status: "",
    address_line_1:"",
    address_line_2:"",
    pin_code:null,
    city:"",
    state: "",
    country : ""
  };

  const [user, setUser] = useState(defaultUserState);
  const [photos, setPhotos] = useState([]); // State for multiple photo uploads
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === "file") {
      if (e.target.multiple) {
        setPhotos(Array.from(files)); // Handle multiple file uploads
      } else {
        setUser((prevUser) => ({
          ...prevUser,
          [name]: files[0],
        }));
      }
    } else {
      setUser((prevUser) => ({
        ...prevUser,
        [name]: value,
      }));
    }
  };

  const handleFileChange = (e) => {
    setUser({
      ...user,
      profilePicture: e.target.files[0], // Capture the selected file
    });
  };

  const handleSubmit = async () => {
    // console.log("User Data:", user);
    // console.log("Uploaded Photos:", photos);
    //validation
    let newErrors = {};
// console.log(user,"lllllllllllllllllllll")
    // Validate Full Name
    if (!user.name) {
      newErrors.name = 'Name is required.';
    }
    if (!user.age) {
      newErrors.age = 'Age is required.';
    } if (!user.gender) {
      newErrors.gender = 'gender is required.';
    } if (!user.mobile_no) {
      newErrors.mobile_no = 'Mobile no is required.';
    } else if (!/^[6-9]/.test(user.mobile_no)) {
      newErrors.mobile_no = 'Mobile no must start with a digit between 6 and 9.';
    } else if (user.mobile_no.length !== 10) {
      newErrors.mobile_no = 'Mobile no must be exactly 10 digits.';
    }
    if (!user.profile_for) {
      newErrors.profile_for = 'profile for is required.';
    }
    if (!user.age_min) {
      newErrors.age_min = ' minimum age is required.';
    }
    if (!user.age_max) {
      newErrors.age_max = ' mmaximum age is required.';
    }
    if (!user.dob) {
      newErrors.dob = 'Dob is required.';
    }
    if (!user.profilePicture) {
      newErrors.profilePicture = 'profile Picture is required.';
    }
    // If there are errors, set them in state and stop submission
    // console.log("newErrors", newErrors)
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const formData = new FormData();
    Object.keys(user).forEach((key) => {
      console.log("Key:", key, "Value:", user[key]);
      formData.append(key, user[key]);
    });

    // Append photo uploads
    photos.forEach((file, index) => {
      formData.append(`profilePicture[]`, file);
    });

    // Log FormData contents
    // console.log("FormData prepared for submission:");
    const formDataContent = {};
    for (const [key, value] of formData.entries()) {
      formDataContent[key] = value;
    }
    console.log("FormData content:", formDataContent);
    console.log("errors content:", errors);

    // // Append photo uploads
    // photos.forEach((file, index) => {
    //   formData.append(`profilePicture[]`, file);
    // });

    // // console.log("FormData prepared for submission.");
    // console.log("jjjjjjjjjjjjjjj",formData)
    try {
      const config = {
        method: "post",
        url: "https://api.clickmee.site/api/profile/store",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formDataContent,
      };
      const response = await axios.request(config);
      // console.log("API Response:", response.data);

      // Reset form state
      setUser(defaultUserState);
      setPhotos([]);
      alert("Profile saved Successfully");
    } catch (error) {
      // console.error("Error during form submission:", error);
      alert("Failed to submit the form. Please try again.");
    }
  };

  return (
    <div className="scrollable-form">
      {isFormVisible && (
        <div>
          <div className="popupheader">
            <h1>Registration Form</h1>
            <button className="popupclose" onClick={closeModal}>
              &times;
            </button>
          </div>
          <div className="scroll-container">
            <PersonalDetailsForm handleChange={handleChange} handleFileChange={handleFileChange} user={user} error={errors} />
            <PartnerPreference handleChange={handleChange} user={user} error={errors} />
          </div>
          <div className="form-buttons">
            <button type="submit" className="submit-btn" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default StepperForm;

// export default StepperForm;


// export default ScrollableForm;