import React from 'react'
import "../../../src/styleSeet.css"
import Profile from '../profie/Profile'
import brandLogo from "../../shaweryLogo.jpeg"
export const Hero = () => {
    return (
        <>
            {/* <div className="experience">
                <div data-aos="flip-up" data-aos-duration="1500" className="aos-init aos-animate">
                    <i>
                        <h1>Our Process</h1>
                        <h2 style={{ marginTop: "30px", marginLeft :"30px", fontSize : "1.5rem" }}>Living experience</h2>
                    </i>
                </div>
                <br />
                <br />
                <br />
                <br />
                <div className="exp-cards">
                    <div className="exp-card">
                        <div className="exp-card-img aos-init aos-animate" data-aos="zoom-out"><img src="/static/media/d4.8f905df0e4e365ae6fa55d84454ce5dc.svg" /></div>
                        <br />
                        <h3>Brokerage Free Accommodation</h3>
                        <br />
                        <p>Rent hassle-free with no brokerage fees or paperwork required at Friendzo.</p>
                    </div>
                    <div className="exp-card">
                        <div className="exp-card-img aos-init aos-animate" data-aos="zoom-out"><img src="/static/media/d3.232b5d26acb9d06c316fa57c64254e8a.svg" /></div>
                        <br />
                        <h3>Secure and Modern Amenities</h3>
                        <br />
                        <p>Enjoy safety and convenience with advanced CCTV &amp; Biometric security, fully furnished rooms, and high-speed WIFI.</p>
                    </div>
                    <div className="exp-card">
                        <div className="exp-card-img aos-init aos-animate" data-aos="zoom-out"><img src="/static/media/d2.ff4a087a78121134cde2c52f476077c3.svg" /></div>
                        <br />
                        <h3>Community-Centric Living</h3>
                        <br />
                        <p>Engage in a vibrant community through events and social spaces designed for networking and interaction.</p>
                    </div>
                    <div className="exp-card">
                        <div className="exp-card-img aos-init aos-animate" data-aos="zoom-out">
                            <svg xmlns="http://www.w3.org/2000/svg" width="126" height="100" viewBox="0 0 126 100" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M59.7356 3.78311C81.7949 4.39402 102.541 16.5308 109.126 37.5135C115.511 57.8621 104.94 79.2426 87.1217 91.0514C71.3861 101.48 51.8734 96.2804 36.0973 85.9127C16.9217 73.3109 -5.22819 56.5206 1.10341 34.522C7.83321 11.1399 35.3282 3.10717 59.7356 3.78311Z" fill="#FF7A05" />
                                <path opacity="0.15" d="M101 38.4259C101 52.7446 89.8071 64.3517 76 70.8333C62.1929 64.3517 51 52.7446 51 38.4259C51 24.1074 62.1929 12.5 76 12.5C89.8071 12.5 101 24.1074 101 38.4259Z" fill="white" />
                                <path d="M43.5168 62.5C40.3261 64.9512 38.5 67.7975 38.5 70.8333C38.5 80.0379 55.2893 87.5 76 87.5C96.7108 87.5 113.5 80.0379 113.5 70.8333C113.5 67.7975 111.674 64.9512 108.483 62.5M76 27.0833V47.9167M65.5833 37.5H76H86.4167M101 38.4259C101 52.7446 89.8071 64.3517 76 70.8333C62.1929 64.3517 51 52.7446 51 38.4259C51 24.1074 62.1929 12.5 76 12.5C89.8071 12.5 101 24.1074 101 38.4259Z" stroke="black" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <br />
                        <h3>Convenient Location</h3>
                        <br />
                        <p>Live conveniently in prime areas with easy access to transportation, shopping, dining, and entertainment options.</p>
                    </div>
                </div>
            </div> */}

            <div className="experience">
                <div data-aos="flip-up" data-aos-duration="1500" className="aos-init aos-animate">
                    <i>
                        <h1>Our Process</h1>
                        <h2 style={{ marginTop: "30px", marginLeft: "30px", fontSize: "1.5rem" }}>Find Your Perfect Match</h2>
                    </i>
                </div>
                <br />
                <br />
                <br />
                <br />
                <div className="exp-cards">
                    <div className="exp-card">
                        <div className="exp-card-img aos-init aos-animate" data-aos="zoom-out">
                            <i className="fa fa-user-plus" style={{ fontSize: '3rem', color: '#FF7A05' }}></i>
                        </div>
                        <br />
                        <h3>Step 1: Register</h3>
                        <br />
                        <p>Sign up on our platform with your details and preferences to create your profile.</p>
                    </div>
                    <div className="exp-card">
                        <div className="exp-card-img aos-init aos-animate" data-aos="zoom-out">
                            <i className="fa fa-search" style={{ fontSize: '3rem', color: '#FF7A05' }}></i>
                        </div>
                        <br />
                        <h3>Step 2: Matches Lsit provided</h3>
                        <br />
                        {/* <p>Browse through profiles tailored to your preferences using our advanced filters.</p> */}
                        <p>According to your preferences Shawery will provide Matched Profile.</p>
                    </div>
                    <div className="exp-card">
                        <div className="exp-card-img aos-init aos-animate" data-aos="zoom-out">
                            <i className="fa fa-comments" style={{ fontSize: '3rem', color: '#FF7A05' }}></i>
                        </div>
                        <br />
                        <h3>Step 3: Connect</h3>
                        <br />
                        <p>Express interest and connect with potential matches for meaningful conversations.</p>
                    </div>
                    <div className="exp-card">
                        <div className="exp-card-img aos-init aos-animate" data-aos="zoom-out">
                            <i className="fa fa-users" style={{ fontSize: '3rem', color: '#FF7A05' }}></i>
                        </div>
                        <br />
                        <h3>Step 4: Meet & Proceed</h3>
                        <br />
                        <p>Plan meetings with mutual consent and take your journey to the next step.</p>
                    </div>
                </div>
            </div>
            {/* profile */}
            <Profile />
            <footer>
                <div className="footer-col">
                    <div className="footer">
                        <div className="footer1">
                            {/* <a href="/"><img style={{ width: "140px" }} src="https://mighty.tools/mockmind-api/content/human/8.jpg" className="home-logo" alt="Logo" /></a><br />
                            <br /> */}
                             <a href="/"><img style={{ width: "200px" }} src={brandLogo} className="home-logo" alt="Logo" /></a><br />
                             <br />
                            <p>Finding the perfect match is effortless with Shawery.</p>
                        </div>
                        <div className="footer-box">
                            <div className="footer2">
                                <ul className="footer-list">
                                    <li><a href="/" style={{ textDecoration: "none", color: "rgb(255, 255, 255)" }}>Home</a></li>
                                    <li><a href="/about-us" style={{ textDecoration: "none", color: "rgb(255, 255, 255)" }}>About Us</a></li>
                                    <li><a href="/contact-us" style={{ textDecoration: "none", color: "rgb(255, 255,255)" }}>Contact Us</a></li>
                                    <li><a href="/privacy-policy" style={{ textDecoration: "none", color: "rgb(255, 255, 255)" }}>Privacy Policy</a></li>
                                    <li><a href="/cookie-policy" style={{ textDecoration: "none", color: "rgb(255, 255, 255)" }}>Cookie Policy</a></li>
                                </ul>
                            </div>
                            <div className="footer2">
                                <ul className="footer-list">
                                    <li>
                                        <h6 className="foot-title">Address :-</h6>
                                        <p className="foot-text">Dadar West,Mumbail,Maharashtra, india, 400001</p>
                                    </li>
                                    <li>
                                        <h6 className="foot-title">Contact:-</h6>
                                        <p className="foot-text">+91 9999999999</p>
                                    </li>
                                    <li>
                                        <h6 className="foot-title">Email :-</h6>
                                        <p className="foot-text">hello@shawery.in</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copy-text"><p>Copyright @ 2024. All rights reserved. SHAWERY.</p></div>
            </footer>

        </>

    )
}
