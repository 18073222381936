
import '../pages/Homepage.css'
import coupleImage from "../image/wed1.png"
import StepperForm from '../componenet/StepperForm/StepperForm.js';
import React, { useState, useEffect } from 'react';
import { Hero } from '../componenet/HeroSection/Hero.js';

// const NikahFinder = () => {
//   // const [searchTerm, setSearchTerm] = useState('');

//   // const handleSearchChange = (e) => {
//   //   setSearchTerm(e.target.value);
//   // };

//   // const handleClearSearch = () => {
//   //   setSearchTerm('');
//   // };
//   const [searchTerm, setSearchTerm] = useState('');
//   // const [showStepperForm, setShowStepperForm] = useState(false);

//   const handleSearchChange = (e) => setSearchTerm(e.target.value);
//   const handleClearSearch = () => setSearchTerm('');
//   const [showStepperForm, setShowStepperForm] = useState(false);

//   const openStepperForm = () => {
//     setShowStepperForm(true);
//   };

//   const closeStepperForm = () => {
//     setShowStepperForm(false);
//   };
//   return (
//     <div className="nikah-finder-container">
//       <header className="header">
//         <h1>Nikah Finder</h1>
//         <div className="search-bar">
//           <div className="search-input-container">
//             <input
//               type="text"
//               placeholder="Search"
//               value={searchTerm}
//               onChange={handleSearchChange}
//             />
//             <button type="button" className="search-button">
//               <i className="fa fa-search"></i>
//             </button>
//           </div>
//           <button type="button" className="clear-button" onClick={handleClearSearch}>
//             <i className="fa fa-trash"></i>
//           </button>
//         </div>
//       </header>

//       <div className="content">
//         <div className="text-content">
//           <p>For those finding pious couples.</p>
//           <h2>Nikah Finder</h2>
//           <p>
//             We provide assistance to widows, divorcees, and unmarried individuals who are facing difficulties in
//             finding a suitable partner. This platform serves as a means of support for those in search of a life partner.
//             In today's modern society, it can be challenging to meet someone without engaging in dating or prohibited
//             relationships. However, Nikah Finder offers an opportunity to discover a righteous and devout spouse.
//           </p>
//           <div className="buttons">
//             <button className="enroll-button">Enroll Membership Now</button>
//             {/* <button className="whatsapp-button">Register Now</button> */}
//             {/* <button className="whatsapp-button" onClick={openStepperForm}>Register Now</button> */}
//             <button className="whatsapp-button" onClick={openStepperForm}>Register Now</button>
//           </div>
//         </div>

//         <div className="image-content">
//           <img src={coupleImage} alt="Couple sharing a meal" className="transparent-image" />
//           {/* <img src  alt="Couple sharing a meal" className="transparent-image" /> */}
//         </div>
//       </div>
//       {/* <div className="vertical-marquee">
//       <div className="vertical-marquee-content">
//         This is a vertically scrolling text effect using CSS animations in React
//       </div>
//       <div className="vertical-marquee-content">
//          scrolling text effect using CSS animations in React.
//       </div>
//     </div> */}

//     <div className="horizontal-marquee">
//       <div className="horizontal-marquee-content">
//         <span > This is a horizontally scrolling text effect using CSS animations in React </span>
//         <span > testing </span>
//         <span > testing </span>
//         <span > testing </span>
//         <span > testing </span>
//       </div>
//     </div>

//     {/* Render StepperForm based on showStepperForm state */}
//     {showStepperForm && (
//         <div className="stepper-modal">
//           <div className="stepper-modal-content">
//             <StepperForm />
//             <button className="close-button" onClick={closeStepperForm}>Close</button>
//           </div>
//         </div>
//       )}

//     </div>


//   );
// };

// export default NikahFinder;



// const NikahFinder = () => {
//   const [searchTerm, setSearchTerm] = useState('');
//   const [showStepperForm, setShowStepperForm] = useState(false);

//   const handleSearchChange = (e) => setSearchTerm(e.target.value);
//   const handleClearSearch = () => setSearchTerm('');

//   // Function to open the stepper form modal
//   const openStepperForm = () => {
//     setShowStepperForm(true); // Show modal when button is clicked
//   };

//   // Function to close the stepper form modal
//   const closeStepperForm = () => {
//     setShowStepperForm(false); // Hide modal when close button is clicked
//   };

//   return (
//     <div className="nikah-finder-container">
//       <header className="header">
//         <h1>Nikah Finder</h1>
//         <div className="search-bar">
//           <div className="search-input-container">
//             <input
//               type="text"
//               placeholder="Search"
//               value={searchTerm}
//               onChange={handleSearchChange}
//             />
//             <button type="button" className="search-button">
//               <i className="fa fa-search"></i>
//             </button>
//           </div>
//           <button type="button" className="clear-button" onClick={handleClearSearch}>
//             <i className="fa fa-trash"></i>
//           </button>
//         </div>
//       </header>

//       <div className="content">
//         <div className="text-content">
//           <p>For those finding pious couples.</p>
//           <h2>Nikah Finder</h2>
//           <p>
//             We provide assistance to widows, divorcees, and unmarried individuals who are facing difficulties in
//             finding a suitable partner. This platform serves as a means of support for those in search of a life partner.
//             In today's modern society, it can be challenging to meet someone without engaging in dating or prohibited
//             relationships. However, Nikah Finder offers an opportunity to discover a righteous and devout spouse.
//           </p>
//           <div className="buttons">
//             <button className="enroll-button">Enroll Membership Now</button>
//             <button className="whatsapp-button" onClick={openStepperForm}>Register Now</button> {/* Button to open StepperForm */}
//           </div>
//         </div>

//         <div className="image-content">
//           <img src={coupleImage} alt="Couple sharing a meal" className="transparent-image" />
//         </div>
//       </div>

//       {/* <div className="horizontal-marquee">
//         <div className="horizontal-marquee-content">
//           <span> This is a horizontally scrolling text effect using CSS animations in React </span>
//           <span> testing </span>
//           <span> testing </span>
//           <span> testing </span>
//           <span> testing </span>
//         </div>
//       </div> */}

//       {/* Render StepperForm modal if showStepperForm is true */}
//       {/* Display the StepperForm if showStepperForm is true */}
//       {showStepperForm && (
//         <div className="stepper-modal">
//           <div className="stepper-modal-content">
//             <StepperForm isFormVisible={showStepperForm} closeModal={closeStepperForm} />
//             {/* <button className="close-button" onClick={closeStepperForm}>Close</button> */}
//           </div>
//         </div>
//       )}
//       {/* profile sec */}
//       <Hero />
//     </div>
//   );
// };

// import { useState, useEffect } from 'react';

const NikahFinder = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showStepperForm, setShowStepperForm] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isPwaInstallVisible, setPwaInstallVisible] = useState(false);

  const handleSearchChange = (e) => setSearchTerm(e.target.value);
  const handleClearSearch = () => setSearchTerm('');

  // Function to open the stepper form modal
  const openStepperForm = () => {
    setShowStepperForm(true);
  };

  // Function to close the stepper form modal
  const closeStepperForm = () => {
    setShowStepperForm(false);
  };

  // Handle PWA installation
  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault(); // Prevent the default browser prompt
      setDeferredPrompt(e); // Save the event for triggering later
      setPwaInstallVisible(true); // Show the custom install button
    };

    // Listen for the beforeinstallprompt event
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handlePwaInstall = async () => {
    if (deferredPrompt) {
      // Show the browser's install prompt
      deferredPrompt.prompt();

      // Wait for the user's response
      const choiceResult = await deferredPrompt.userChoice;
      console.log('User choice:', choiceResult.outcome); // Log the user's choice

      // Clear the deferred prompt and hide the install button
      setDeferredPrompt(null);
      setPwaInstallVisible(false);
    }
  };

  return (
    <div className="nikah-finder-container">
      <header className="header">
        <h1>SHAWERY</h1>
        <div className="header-actions">
          {/* PWA Install Button */}
          {isPwaInstallVisible && (
            <button className="install-button" onClick={handlePwaInstall}>
              <i className="fa fa-download"></i> Install App
            </button>
          )}
        </div>
        {/* <div className="search-bar">
          <div className="search-input-container">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <button type="button" className="search-button">
              <i className="fa fa-search"></i>
            </button>
          </div>
          <button type="button" className="clear-button" onClick={handleClearSearch}>
            <i className="fa fa-trash"></i>
          </button>
        </div> */}
      </header>

      <div className="content">
        <div className="text-content">
          {/* <p>For those finding pious couples.</p> */}
          <p>Building Marriages on Values, Not Dowry.</p>
          <h2>Shaadi Without Dowry</h2>
          <p>
            We are dedicated to helping widows, divorcees, and unmarried individuals find suitable and righteous life partners. Our platform is a beacon of support for those committed to a marriage free from the burdens of dowry.

            In today's fast-paced world, finding a devout and compatible partner without resorting to dating or prohibited relationships can be challenging. Our mission is to bridge this gap by providing a trusted space for individuals seeking a blessed union rooted in values and mutual respect.

            Shawery is your path to discovering a spouse who values piety and principles above materialistic demands. Join us to build a future based on love, respect, and shared faith.
          </p>
          <div className="buttons">
            {/* <button className="enroll-button">Enroll Membership Now</button> */}
            <button className="whatsapp-button" onClick={openStepperForm}>Register Now</button>
          </div>
        </div>

        <div className="image-content">
          <img src={coupleImage} alt="Couple sharing a meal" className="transparent-image" />
        </div>
      </div>

      {/* Render StepperForm modal if showStepperForm is true */}
      {showStepperForm && (
        <div className="stepper-modal">
          <div className="stepper-modal-content">
            <StepperForm isFormVisible={showStepperForm} closeModal={closeStepperForm} />
          </div>
        </div>
      )}

      <Hero />
    </div>
  );
};


export default NikahFinder;


